* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

*:focus {
  outline:0;
}

html {
   height:100%;
}

body {
  font-weight: normal;
  min-height:100%;
  overflow-x: hidden;
}

a{
  text-decoration: none;
  color: inherit;
}

p a{
  position: relative;
  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: $lightgrey;
    position: absolute;
    bottom: 2px;
    left: 0
    
  }
}

.svg-container{
  display: none;
}


.vh{
  visibility: hidden;
}

.bg-blue{
  background: $blue;
  color: $white;
}

.c-blue{
  color: $blue;
}

.center-xs.tal{
  text-align: left;
}

.mb-5{
  margin-bottom: 5vw;
}

.mb-3{
  margin-bottom: 3vw;
}

.mb-2{
  margin-bottom: 2vw;
}

.ta-l{
  text-align: left;
}

.pl-2{
  padding-left: 2vw !important;
}



body{
  .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-offset-0, .col-xs-offset-1, .col-xs-offset-2, .col-xs-offset-3, .col-xs-offset-4, .col-xs-offset-5, .col-xs-offset-6, .col-xs-offset-7, .col-xs-offset-8, .col-xs-offset-9, .col-xs-offset-10, .col-xs-offset-11, .col-xs-offset-12{
    padding: 0;
  }

  .row{
    margin-right: 0;
    margin-left: 0;
  }

}



