.gallery{
	padding-bottom: 5rem;
	padding-top: 5rem;
}

.slick-slide{
	margin-left: 3px;
	margin-right: 3px;
}

.gallery{
	.slick-slide{
		img{
			height: auto;
	    	width: 100%;
		}
	}
}

.slick-dots{
	display: flex !important;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: 2rem;
	position: absolute;
	width: 100%;

	li{
		flex: 0 1 auto;
		margin-left: 3px;
		margin-right: 3px;
		cursor: pointer;

		button{
			font-size: 0;
			width: 13px;
			height: 13px;
			border-radius: 50%;
			background: #fff;
			border: 1px solid $white;
			transition: all 0.3s;
		}

		&.slick-active,&:hover{
			button{
				background: $blue;
			}
		}
	}

}

.slick-arrow{
	position: absolute;
	width: 4vw;
	z-index: 5;
	top: 50%;
	transform:translateY(-50%);

	&.slick-prev{
		left: -5vw;
	}

	&.slick-next{
		right: -5vw;
	}


	svg{
		fill: $white;
		width: 100%;
		height: auto;
	}
}


.img-wrap.para{
	img{
		width: 100%;
		height: auto;
	}
	overflow: hidden;
	/* width: 100%;
	height: auto;
	background-size: cover;
	background-position: top center;
	background-attachment: fixed;
	@include breakpoint('m'){
		background-attachment: normal;
	}
	&:before{
		content: '';
		padding-top: 40%;
		display: block;
	} */
}
