.forminfo{
		width: 100%;
		display: block;
		margin-bottom: 30px
	}

.formgen{

	.formerror{
		color: $blue;
	}
	label{
		display: block;
		width: 100%;
	}
	input,textarea{
		width: 100%;
		margin-bottom: 0.5em;
		padding-left: 5px;
		padding-right: 5px;
		border: 1px solid $lightgrey;
		-webkit-appearance: none;
   		-moz-appearance:    none;
   		appearance:         none;
	}

	input{
		height: 25px;
		line-height: 25px;
	}
	
}


input[type='submit'],.button{
		background: $blue;
		display: inline-block;
		border: 1px solid $blue;
		color: $white;
		text-transform: uppercase;
		width: auto;
		padding-left: 20px;
		padding-right: 20px;
		height: 40px;
		line-height: 40px;
		cursor: pointer;

		transition: all 0.25s;

		&:hover{
			background: $white;
			color: $blue;
		}
	}