.text > .col-xs-6 > img{
	width: 100%;
	height: auto;
	padding: 4px;
}

.id2 article:last-child .text{
	display: flex;
}


#mapContainer{
	width: 100%;
	height: 370px;
	position: relative;
	margin-top: 20px;
	margin-bottom: 1em;

	#map{
		position: absolute;
		width: 100%;
		height: 100%;
	}
}

.id3 .col-md{
	flex-basis: 50%;
}