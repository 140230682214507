.animated {
    visibility: visible !important;
    animation-duration: 1s;
    animation-fill-mode: both;
}


.show-animated{
	visibility: hidden;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
    animation-name: fadeInUp;
}








