nav{

	background: rgba(255,255,255,0.9);
	position: absolute;
	top: -500px;
	transition: all 0.5s;
	padding-top: 20px;
	z-index: 9;


	@include breakpoint('s'){
		position: relative;
		top: auto;
	}

	.navOpen &{
		top: 0;
	}

	ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 50px;


		@include breakpoint('s'){
			justify-content: space-between;
			margin-top: 0;
		}

		li{
			flex: 0 1 auto;
			width: 100%;
			height: 40px;
			@include breakpoint('s'){
				width: auto;
				height: auto;
			}
		}

		a{
			text-transform: uppercase;
			display: block;
			line-height: 40px;

			@include breakpoint('s'){
				display: inline-block;
				line-height: auto;
			}
			&.rex-current,&:hover{
				color: $blue;
				font-weight: 600;
				text-align: center;
			}

			&:after{
				display: block;
			    content: attr(title);
			    font-weight: 600;
			    height: 100%;
			    color: transparent;
			    overflow: hidden;
			    visibility: hidden;
			    margin-bottom: -1px;
			}
		}
	}
}

.showNav{
	position: absolute;
	top: 20px;
	right: 20px;
	width: 40px;
	height: 30px;
	background:$white;
	cursor: pointer;
	z-index: 10;
	@include breakpoint('s'){
		display: none;
	}

	span,&:before,&:after{
		height: 4px;
		position: absolute;
		width: 100%;
		display: block;
		content: '';
		background: $blue;
		opacity:1;
		transition: all 0.3s;
		transform: rotate(0deg);
		top: 0;
		
	}

	span{
		top: 13px;
	}

	&:after{
		top: 26px;
	}

	.navOpen &{
		span{
			opacity: 0;
		}
		&:after{
			transform: rotate(45deg);
			top: 14px;
		}
		&:before{
			transform: rotate(-45deg);
			top: 14px;
		}
	}
}



