@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,600');


body{
	font-family: 'Source Sans Pro', sans-serif;
	line-height: 1.5;
}

input,textarea{
	font-family: 'Source Sans Pro', sans-serif;
}

h1,h2{
	color: $blue;
	text-transform: uppercase;
	@include responsive-font(8vw, 20px, 50px);
	margin: 1vw 0;
	width: 100%;
}

.bg-blue{
	h1,h2{
		color: $white;
	}
}

h2,h3{
	font-weight: 300;
	text-transform: uppercase;
	@include responsive-font(5vw, 15px, 30px);
	margin: 1vw 0;
}




h1 + h2{
	margin-top: -1vw;
	margin-bottom: 2vw;
	font-weight: 300;
}