.logo{
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.logo a{
	display: inline-block;

	img{
		width: 100%;
		height: auto;
	}
}