.slick-lightbox{
	.slick-lightbox-slick-caption{
		color: $black;
		h3{
			color: $blue;
		}
	}

	.slick-lightbox-close{
		width: 3vw;
		height: 3vw;

		svg{
			width: 100%;
			height: 100%;
			fill: $blue;
		}
		&:before{
			display: none;
		}
	}

	.slick-arrow{
		background: none;
		background-size: contain;
		background-repeat: no-repeat;
		height: 40px;
		height: 5vw;
		width: 4vw;
		border: none;
		font-size: 0;
		&.slick-prev{
			left: 2.5vw;
			background-image: url(../img/icon-arrow-left.svg);
		}

		&.slick-next{
			right: 2.2vw;
			background-image: url(../img/icon-arrow-right.svg);
		}


	}
}